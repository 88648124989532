@import "brc-theme/mixins";
@import "brc-theme/extend";
@import "brc-theme/palette";
@import "brc-theme/responsive";


* {	box-sizing: border-box; }

body {
	background: #EAEEF1;
	@extend .font-open-sans;
}



// Top Navigation
.top-user-navs {
		margin-top: 12px;
		ul {
			list-style: none;
			float: right;
			font-size: 11px;
			> li {
				color: $main-gray;
				position: relative;
				// padding: 0 20px 0 10px;
				padding: 0 5px;
				float: left;
				display: inline;

				&:after {
					@extend .before-after;
					height: 12px;
					right: 1px;
					top: 3px;
					width: 1px;
					border-right: 1px solid $main-blue;
				}

				&:last-child {
					&:after {
						border: none;
						padding-right: 0;
					}

				}

				a {
					color: $main-blue;
					font-size: 11px;
					position: relative;
					i {
						font-size: 18px;
						padding: 0;
						position: relative;
						float: right;
						// top: 6px;
						// left: -3px;
					}
				}
			}

		}
}

.manage-account {
	.dropdown-menu {
		border-radius: 0;
		border: none;
		margin-top: 5px;
		padding: 20px;
		right: 3px;
		min-width: 347px;
		left: auto;

		[class*="col-"] {
			&:first-child {
				padding-right: 0;
			}
		}

		.thumbnail {
			margin-bottom: 0;
		}

		.thumb {
			background: #D8D8D8;
			border-radius: 2px;
			width: 100%;
			min-height: 100px;

			img {
				// max-width: 100px;
				margin-bottom: 0;
			}
		}

		h4 {
			margin-top: 10px;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		a {
			color: $main-blue-link;
		}
		a.btn, .btn, button {
			margin-top: 15px;
			display: block;
			width: 100%;
			border-radius: 2px;
			background: $main-blue-link !important;
			color: #fff;

			&:hover, &:focus, &:visited {
				background: darken($main-blue-link, 10%) !important;
			}
		}
	}

}



// Main Container Wrap
.main-container-wrap {
	border-radius: 6px;
	background: #fff;
	margin-bottom: 60px;
	overflow: hidden;
	box-shadow: 0px 0px 30px 0px rgba(180,207,213,0.29);


	.header-wrap {
		min-height: 110px;
		position: relative;
		border-bottom: 4px solid #F2F2F2;
		// z-index: 5;


		.brand-wrap {
			min-height: 110px;
			border-right: 1px solid #EBF1F5;
			text-align: center;
			padding-top: 20px;

			img {
				width: 107px;
				margin: 0 auto;
				display: block;
			}

			h4 {
				text-transform: uppercase;
				font-weight: 700;
				font-size: 10px;
				margin: 0;
				padding: 5px 0;
			}
		}
	}

	.main-nav-wrap {
		margin: 0;
		min-height: 110px;

		@include mq(md-device) {
			.dropdown:hover .dropdown-menu {
				display: block;
			}
		}

		@include mq(sm-device) {
			.dropdown:hover .dropdown-menu {
				display: block;
			}
		}




		ul.nav.navbar-nav {
			padding-top: 30px;
			width: 100%;
			> li {
				margin: 0 10px;
				&.active {
					a {
						background: none;
						color: $main-black;
					}
				}

				> a {
					font-size: 10px;
					text-transform: uppercase;
					font-weight: 700;
					color: $main-gray;

					&:hover, &:focus {
						background: none;
						color: $main-black;
					}

				}
			}
		}
	}

	.banner-wrap {
		color: #fff;
		overflow: hidden;
		width: 100%;
		position: relative;
		// background: gold;
		height: 100%;
		display: block;
		float: none;


		.sidebar-menu-left {
			height: 322px;
			background: $main-black;
			ul {
				margin: 0;
				padding: 20px 0;
				li {
					list-style: none;
					a {
						float: left;
						display: block;
						// background: #cecece;
						font-size: 12px;
						font-weight: 700;
						color: #fff;
						width: 100%;
						padding: 7px;

					}
				}
			}
		}

		.banner-bg-img {
			background: $main-black;
			// background: #f00;
			min-height: 322px;
			position: relative;
			z-index: 1;
			// overflow: hidden;
			// text-align: center;

			&:before {
				@extend .before-after;
				box-shadow: inset 0px 0px 20px 0px rgba(74,74,74,0.98);
				background: url('../img/bg-header.png') center center no-repeat;
				background-size: cover;
				left: 0;
				top: 0;
				z-index: -1;
			}

			h1 {
				text-align: center;
				font-size: 26px;
				font-weight: 700;
				margin: 0;
				padding: 40px 0 20px;
			}
		}

		&.welcome-blade {
			.banner-bg-img {
				width: 100%;
				overflow: hidden;
				min-height: 390px;
				color: #E1E1E1;

				p {
					color: #E1E1E1;
					span {
						color: #fff;
					}
				}

				h1 {
					padding-top: 70px;
					color: #fff;
				}

				a {
					color: #fff;
					text-decoration: underline;
				}
			}
		}
	}

	.content-wrap {
		overflow: hidden;
		padding: 20px 0 60px;
		position: relative;
        border-top: 1px solid #fff;
        z-index: 2;

        &:after {
        	background: linear-gradient(-179deg, rgba(0,0,0,0.05) 0%, rgba(255,255,255,0.00) 50%);
        	@include absolute;
        	z-index: -1;
        	left: 0;
        	top: 0;
        	height: 130px;
        }

		h2 {
			font-size: 18px;
			font-weight: 700;
			text-transform: uppercase;
		}

		// Two Column Layout
		.two-col-layout-right {
			padding-left: 20px;
			padding-right: 20px;
		}

		// Left Sidebar For Page
		.sidebar-left {
			padding-right: 0;
		}
	}
}


.footer-wrap{
	background: $main-black;
	overflow: hidden;
	padding: 40px 0;
	color: #fff;
	float: left;
	width: 100%;
	display: block;

	.brand-footer {
		img {
			margin: 0 auto;
			display: block;
		}

		h3 {
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;
			margin: 0;
			padding: 5px;
		}

		p {
			padding: 5px;
			font-size: 12px;
		}

		.read-more {
			float: right;
			font-size: 10px;
		}
	}

	h4 {
		color: #fff;
		font-size: 14px;
		font-weight: 700;
		border-bottom: 1px solid #484F58;
		padding-bottom: 10px;
		margin-bottom: 5px;
	}

	ul, ul.nav.navbar-nav {
		margin: 0;
		padding: 0 15px;
		li {
			list-style: none;
			float: left;
			display: block;
			width: 100%;
			a {
				font-size: 12px;
				padding: 2px 0;
				width: 100%;
				float: left;
				display: block;
				background: none;
				&:hover {
					color: #fff;
				}
			}
		}
	}

	a {
		color: #91969C;
		background: none;
	}

	.copy {
		width: 98%;
		text-align: center;
		border-top: 1px dotted #484F58;
		margin: 40px auto 0;
		display: block;
		position: relative;
		padding: 10px 0;
		color: #91969C;
		font-size: 12px;
	}

	.dropdown-menu {
		padding: 5px 0 !important;

		a {
			color: #262626 !important;
		}
	}

	.dropdown-menu > li > a {
		padding: 3px 20px !important;
	}
}

.footer-wrap ul li a:hover, .footer-wrap ul.nav.navbar-nav li a:hover {
	color: #262626 !important;
}


.footer-welcome-blade-wrap {
 	height: 70px;
 	text-align: center;
 	padding-top: 30px;
 	p {
 		font-size: 12px;
 	}
}


// Welcome Blade
.welcome-blade-main-wrap {
	.main-container-wrap {
		margin-top: 60px;
	}

	.header-wrap {
		height: 130px;

		.brand-wrap {
			height: 130px;
		}
	}

	.banner-wrap {
		.banner-bg-img  {
			&:before {
				opacity: .5;
			}
		}
		.dropdown-menu{
			padding: 5px 0 !important;
		}
	}
}

.banner-wrap .dropdown-menu>li>a{
	padding: 3px 20px !important;
	font-weight: 400 !important;
	color: #333 !important;
}

.banner-wrap .dropdown-submenu > .dropdown-menu{
	margin-top: -6px !important;
}

// Master Pages Fixes
.master-page {
	.content-wrap {
		padding: 20px 0 60px;
	}
}


.reset-wrap {
	width: 80%;
	margin: 60px auto !important;
}



// Breadcrumbs and Search Bar Separator
.bread-search-separator {
	// border-bottom: 1px dotted #F0F0F0;
	// border-bottom: 1px dotted #F00;
	border-bottom: 1px dotted #DEDEDE;
	margin-bottom: 20px;
	overflow: hidden;
	width: auto;
	display: block;
	// padding-bottom: 10px;
	margin-right: 15px;
	margin-left: 15px;

	> .col-md-9, > .col-md-7 {
		padding-left: 0;
	}

	> .col-md-3, > .col-md-5 {
		padding-right: 0;
	}

	.search-bar-wrap {
		position: relative;
		border: 1px solid #DEDEDE;
		border-bottom: none;
		// top: 5px;
		// right: 0;
		// float: right;
		overflow: hidden;
		background: #f8f8f8;
		padding: 10px 0;
		border-radius: 2px 0;

		.form-group {
			// float: right;
			margin-left: 10px;
			// width: 260px;
			width: 95%;
			input {
				float: left;
				// width: 177px;
				box-shadow: none;
				border-radius: 3px 0 0 3px;
				border-right: none;
				width: 8/12*100%;
			}

			button {
				float: left;
				// width: 70px;
				border-radius: 0 3px 3px 0;
				width: 4/12*100%;
			}
		}
	}
}




// This will contain all of responsive fixes for BRC theme

// Mobile size
@include mq(xs-device) {
	.top-user-navs {
		width: 100%;
		ul {
			width: 100%;
			float: left;
			padding: 0;
		}
	}

	.manage-account {
		ul {
			position: relative;
			li {
				position: initial !important;
				border-right: 1px solid #c2cede;

				&:last-child {
					border: none;
					// display: none;
				}

				// &:nth-child(2) {
				// 	border: none;
				// 	padding-left: 10px;
				// }

				&:after {
					display: none;
				}

				.dropdown-menu {
					left: 0 !important;
					right: auto !important;
					min-width: 300px !important;
					margin-top: 5px !important;
					padding: 20px 10px !important;
				}
			}
		}

	}

	// Header Navigation
	.main-container-wrap {
		.header-wrap {
			border: none;
			.brand-wrap {
				a {
					display: block;
				}
			}
			> .col-md-10 {
				padding: 0;
			}

			.navbar-header {
				background: #37404D;
				.navbar-toggle {
					background: #fff;
					padding: 3px 6px 0;
				}
			}
		}

		.main-nav-wrap {

			.navbar-collapse {
				background: #37404D;
			}
			border: none;
			min-height: auto;
			ul.nav.navbar-nav {
				padding: 0;
				margin: 0;

				> li {
					border-bottom: 1px solid #505050;

					&.open  {
						.dropdown-menu {
							padding: 0;
							margin: 0 auto;
							width: 95%;
							> li {
								border-bottom: 1px dotted #505050;
								&:last-child {
									border: none;
								}
								> a {
									color: #B6B7BB;
									padding: 5px;

									&:hover {
										background: none;
										color: #fff;
									}
								}
							}


						}
					}

					&:last-child {
						border: none;
					}
					> a {
						font-size: 12px;
						padding: 6px 0;

						&:hover {
							background: none;
							color: #fff;
						}
					}
				}
			}
		}


	}


	.poke-balls-wrap {
		width: 100%;

		li {
			width: 6/12*100%;
			margin: 20px 0;
		}
	}

	.breadcrumb {
		left: 0 !important;
		top: 0 !important;
		margin-bottom: 20px !important;
	}

	.bread-search-separator > .col-md-5 {
		padding-left: 0;
	}

// end mixin mq(xs-device)
}
