@import "responsive";
@import "palette";

.font-open-sans {
	font-family: 'Open Sans', sans-serif;
}

.font-merriweather {
	font-family: 'Merriweather', serif;
}


.before-after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
}



// Reusable Poke Balls
.poke-balls-wrap {
	margin: 0 auto;
	padding: 0;
	width: 720px;
	overflow: hidden;
	li {
		list-style: none;
		float: left;
		display: inline;
		margin: 0 30px;
		a {
			text-align: center;
			color: #fff;
			display: block;
			text-decoration: none;

			&:hover {
				.poke-balls {
					&.info {
						border-color: darken($info-balls, 15%) !important;

						i {
							color: darken($info-balls, 15%) !important;
						}
					}

					&.success {
						border-color: darken($success-balls, 15%) !important;

						i {
							color: darken($success-balls, 15%) !important;
						}
					}

					&.warning {
						border-color: darken($warning-balls, 15%) !important;

						i {
							color: darken($warning-balls, 15%) !important;
						}
					}

					&.warning-b {
						border-color: darken($warning-b-balls, 15%) !important;

						i {
							color: darken($warning-b-balls, 15%) !important;
						}
					}
				}
			}

			.poke-balls {
				width: 95px;
				height: 95px;
				// border: 4px solid #58CEE8;
				border: 4px solid #fff;
				background: none;
				border-radius: 100px;
				margin: 0 auto;

				&.info {
					border-color: $info-balls !important;

					i {
						color: $info-balls !important;
					}
				}

				&.success {
					border-color: $success-balls !important;

					i {
						color: $success-balls !important;
					}
				}

				&.warning {
					border-color: $warning-balls !important;

					i {
						color: $warning-balls !important;
					}
				}

				&.warning-b {
					border-color: $warning-b-balls !important;

					i {
						color: $warning-b-balls !important;
					}
				}
			}
			h4 {
				font-size: 14px;
				font-weight: 700;
			}

			span {
				font-size: 32px;
				// font-weight: 700;
				display: block;
				top: 12px;
				position: relative;
			}

			i {
				font-size: 28px;
			}
		}
	}
}



// Reusable Box and Latest News

.box-widget {
	background: #F8F8F8;
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.17) !important;
	overflow: hidden;
	border: none !important;
	border-radius: 2px;
	margin-bottom: 20px;

	.box-header {
		background: $main-black;
		border-radius: 2px 2px 0 0;
		padding: 10px 15px !important;
		color: #fff;
		// font-weight: 700;
		font-size: 14px;
		border: none;

		h4 {
			color: #fff;
			margin: 0;
			padding: 0;
			font-weight: 700;
			font-size: 14px;
		}

		// .panel-title {
		// 	font-weight: normal !important;
		// 	font-size: 14px !important;
		// }
	}

	.box-body {
		padding: 15px 15px 20px;
		position: relative;
		overflow: hidden;
	}

	ul {
		margin: 0;
		padding: 0 0 5px 5px;
		overflow: hidden;
		li {
			list-style: none;
			float: left;
			display: block;
			width: 100%;
			border-bottom: 1px dotted #d6d6d6;

			&:last-child {
				border: none;
			}

			a {
				color: $main-blue-link;
				font-size: 13px;
				padding: 5px 0;
				float: left;
				display: block;
				width: 100%;

				&:hover {
					color: latest($main-blue-link, 10%);
				}
			}
		}
	}

	// IF Box Testimonials
	&.is-box-testi {
		.box-header {
			background: none;
			color: $main-black;
			padding: 30px 0 20px !important;
			padding-left: 15px;
			padding-right: 15px;

			h4 {
				color: $main-black;
				text-align: center;
			}
		}

		.box-body {
			padding: 0;
			.img-holder {
				width: 86px;
				height: 86px;
				margin: 0 auto;
				border-radius: 100px;
				border: 2px solid #fff;
				display: block;
				overflow: hidden;
				background: #dedede;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			span {
				display: block;
				text-align: center;
				font-weight: bold;
				color: $main-black;
				font-size: 12px;
				margin: 5px 0 15px;
			}

			.agent-testi {
				background: $main-black;
				padding: 25px;
				margin-top: 20px;
				position: relative;

				&:after {
					@include absolute;
					left: 48%;
					top: -10px;
					width: 0;
					height: 0;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-bottom: 10px solid $main-black;
				}

				p {
					text-align: center;
					font-style: italic;
					margin: 0;
					color: #fff;

					span {
						font-weight: bold;
						display: block;
					}
				}
			}
		}

		&.is-sm {
			.box-header {
				h4 {
					font-size: 13px;
				}
			}

			.box-body {
				.agent-testi {
					padding: 20px;
					p {
						font-size: 12px;
					}

					&:after {
						left: 45%;
					}
				}
			}
		}
	}
}

.box-latest-news {
	// min-height: 390px;
	min-height: 290px;

	.box-header {
		padding: 15px !important;
		h4 {
			@extend .font-merriweather;
		}
	}

	.box-body {

		.list-news {
			padding: 0;
			margin: 0;
			li {
				float: left;
				display: block;
				list-style: none;
				border-bottom: 1px dotted #c9c9c9;
				// margin: 0 0 10px;
				// padding-bottom: 10px;
				padding-bottom: 5px;
				margin: 0 0 5px;
				position: relative;
				z-index: 1;

				&:before {
					@include absolute;
					left: 5px;
					// top: 25%;
					top: 4px;
					content: "- ";
					width: 10px;
					height: 30px;
					z-index: -1;
					color: #2D72C2;
				}
				a {
					float: left;
					display: block;
					color: #2D72C2;
					padding: 6px 0 6px 15px;
					font-size: 13px;
				}

				&:last-child {
					border: none;
				}
			}
		}
	}

	.view-all {
		color: $main-black;
		font-size: 13px;
		text-align: right;
		display: block;
		position: absolute;
		bottom: 10px;
		right: 20px;
	}


}


.form-top-wrap {
	padding: 15px 15px 10px;
	color: $main-black;

	@include mq(xs-device) {
		float: none !important;
	}
	.form-group {
		display: block;
		padding-top: 5px;
		margin-bottom: 5px;

		@include mq(xs-device) {
			margin: 0 !important;
			.input-xs-st {
				padding-left: 0;
			}
		}




		label {
			color: $main-black;
			padding: 3px 0 0;
			font-size: 12px;
		}

		input {
			height: 28px;
			border-radius: 2px;
			background: #F7F7F7;
			border: 1px solid #e4e4e4;
			box-shadow: none;
			color: #101010;
		}

		> [class*="col-"] {
			@include mq(sm-device) {
				padding: 0 15px 0 0;
				margin-right: 15px;
			}

		}

	}

	.row {
		> [class*="col-"] {
			padding-right: 0;

		}
	}

	a {
		text-decoration: underline;
			// color: #B7B7B7;
			// color: #fff;
			color: $main-blue-link !important;
			padding-left: 10px;
			font-size: 12px;

			&:hover {
				color: darken($main-blue-link, 8%);
			}
		}

		.checkbox-newold {
			position: relative;
			float: right;
			display: block;

			@include mq(xs-device) {
				float: left;
				padding-left: 15px;
			}

			&:after {
				@extend .before-after;
				border-right: 1px solid #EBF1F5;
				opacity: .3;
				height: 20px;
				right: -15px;
				top: 0;

				@include mq(sm-device) {
					right: -7px;
				}
			}

			label {
				float: right;
				font-weight: normal;
				font-size: 12px;
			}
		}

		button {
			border-radius: 2px;
			height: 27px;
			background: $main-blue-link;
			text-transform: none;
			color: #fff;
			line-height: 10px;
			border: none;
			font-size: 12px;

			&:hover {
				background: darken($main-blue-link, 8%);
				color: #fff;
			}

			@include mq(md-device) {
				position: relative;
				top: 44px;
			}

			@include mq(lg-device) {
				position: relative;
				top: 44px;
			}
		}

		.forgot-items-list {

			ul {
				float: right;
				margin: 0;
				padding: 0;
				li {
					float: left;
					display: inline;
					margin-right: 8px;
					padding-right: 8px;
					position: relative;


					&:after {
						@extend .before-after;
						border-right: 1px solid $main-gray;
						height: 15px;
						opacity: 0.3;
						right: 2px;
						top: 3px;
						width: 2px;
					}

					&:last-child {
						padding-right: 0;
						margin-right: 0;

						&:after {
							border: none;
						}
					}

					a {
						float: left;
						display: inline;
						position: relative;
						padding: 2px 0 0;
					}
				}
			}

			.checkbox {
				margin: 0;
				padding: 0;

				input[type="checkbox"]  {
					margin-left: 2px;
					margin-right: auto;
					right: 2px;
					top: 0;
				}

				label {
					color: $main-blue-link;
					padding-right: 20px;
					padding-left: auto;
					font-size: 12px;
					font-weight: normal;
					cursor: pointer;
				}

			}

			@include mq(xs-device) {
				margin-top: 10px;
				ul {
					float: left;
					position: relative;
					left: 5px;
				}
			}

			@include mq(sm-device) {
				margin-top: 10px;
				ul {
					float: left;
					position: relative;
					left: -20px;
				}
			}
		}
	}

	// Breadcrumbs
	.breadcrumb {
		background: none;
		border-radius: 0;
		list-style: outside none none;
		font-size: 12px;
		// margin-bottom: 0;
		// border-bottom: 1px dotted #F0F0F0;
		// margin: 5px 15px 20px;
		// padding: 0 0 5px;
		padding: 16px 0 0;
		margin: 0;
		position: relative;
		left: 5px;
		top: 10px;

		i {
			font-size: 14px;
			position: relative;
			top: 1px;
		}

		a {
			font-size: 12px;
		}

	}


	// PANEL Bootstrap Over Ride
	.panel {
		// background: linear-gradient(-180deg, rgba(240,240,240,0.50) 0%, rgba(243,243,243,0.50) 100%);
		// border-radius: 4px 4px 0px 0px;
        // background: linear-gradient(-180deg, #F6F6F6 0%, #EAEEF1 100%);
	}


	.panel {
		background: #F8F8F8;
		box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.17) !important;
		overflow: hidden;
		border: none !important;
		border-radius: 2px;
		margin-bottom: 20px;

		label {
			font-size: 13px;
		}

		.panel-body {
			.title-body {
				font-weight: bold;
				font-size: 14px;
				border-bottom: 1px dotted #dedede;
				padding-bottom: 12px;
			}
		}

		.panel-heading {
			background: $main-black;
			border-bottom: 1px solid #fff;
			color: #fff;
			font-size: 14px;

			.panel-title {
				color: #fff;
				font-size: 14px;

			}
		}

		// Tables Over Ride
		.table {
			background: #fff;
			thead, th {
				font-size: 12px;
			}
			td {
				background: #fff;
			}
		}



		&.is-panel-white {
			background: #fff;
			border-radius: 4px;
			box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.00);
			margin-bottom: 30px;
			.panel-heading {
				border-radius: 4px 4px 0 0;
				background: #F2F2F2;
				text-align: center;
				padding: 30px 20px;
				color: $main-black;
				text-align: center;
				font-size: 22px;
				font-weight: normal;
				margin-bottom: 20px;
				padding-bottom: 25px;

				.sub-title {
					font-size: 13px;
					display: block;
					color: #9B9B9B;
				}
			}

			.panel-body {
				label {
					color: $main-black;
				}

				i {
					margin-right: 10px;
					position: relative;
					font-size: 16px;
				}

				button, .btn {
					font-size: 12px;
				}
			}

			.help-block {
				font-size: 13px;
			}
		}
	}

// Aricles
.article-blog-solo-wrap, .category-article, .show-search-results-wrap {
	padding: 0 20px;
	.article-title {
		@extend .font-merriweather;
		font-size: 22px;
		font-weight: bold;
		border-bottom: 1px dotted #dedede !important;
		padding: 15px 0 8px 0 !important;
		margin: 0 0 10px;
		overflow: hidden;

		a {
			text-decoration: none;
			width: 100%;
			float: left;
		}
	}

	.article-details {
		width: 100%;
		display: block;
		float: left;
		margin: 0;
		p {
			color: #c5c5c5;
			float: left;
			padding: 0 8px;
			border-right: 1px solid #dedede;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
				border: none;
			}
		}
	}

	.content-info {
		width: 100%;
		float: left;
		display: block;
		margin: 20px 0;
	}
}


.show-search-results-wrap {
	h2 {
		font-size: 22px;
		padding-bottom: 20px;
	}

	ul.list-search-results {
		margin: 0;
		padding: 0 0 0 10px;
		li {
			border-bottom: 1px solid #f3f3f3;
			// padding: 15px 0 8px;
			padding: 8px 0;
			list-style: none;
			float: left;
			width: 100%;
			overflow: hidden;
			p {
				font-size: 16px;
				color: #c5c5c5;

				.date-created {
					font-size: 13px;
				}

				a {
					font-weight: bold;
				}
			}
		}
	}
}


.category-wrap {
	.category-article {
		padding: 0 40px;
		margin: 20px 0;
		overflow: hidden;

		.article-title {
			font-size: 18px;
			border: none !important;
			padding: 3px 0;
			margin: 0;
		}

		.article-details {
			border-bottom: 1px solid #f3f3f3;
		}

	}

	.article-title-page {
		@extend .font-merriweather;
		font-size: 22px;
		font-weight: bold;
		border-bottom: 1px dotted #dedede !important;
		padding: 15px 0 8px 0 !important;
		// margin: 0 0 10px;
		margin: 0 20px;
		overflow: hidden;
	}

	.pagination {
		padding-left: 38px;
	}
}


// Login Admin
// body {
// 	background: #EFEFEF;
// 	@extend .font-open-sans;
// }

// h1, h2, h3, h4, h5, h6 {
// 	@extend .font-open-sans;
// }

.vertical-align-wrap {
	// @extend .v-align-middle;
	min-height: 600px;
}

// LOGIN ITEMS
.login-admin{
	.container {
		max-width: 743px;
	}
}

.login-admin-box-wrap {
	background: #fff;
	border-radius: 4px;
	position: relative;
	min-height: 340px;
	margin-top: 160px;

	&:before {
		bottom: 0;
		box-shadow: 0 2px 60px 0 #717171;
		content: "";
		height: 50px;
		left: 13%;
		position: absolute;
		width: 75%;
		z-index: -1;
	}
}


.left-content {
	// background: url('../img/bg-header.png') center center no-repeat $main-black;
	background: $main-black;
	min-height: 340px;
	border-radius: 4px 0 0 4px;
	overflow: hidden;
	position: relative;
	z-index: 1;

	&:before {
		@extend .before-after;
		background: url('../img/bg-header.png') center center no-repeat;
		background-size: cover;
		left: 0;
		top: 0;
		z-index: -1;
		opacity: .5;
	}


	h4 {
		text-align: center;
		font-weight: 300;
		line-height: 24px;
	}

	.v-middle {
		display: table;
		height: 340px;
		width: 100%;
	}

	.v-middle-content {
		text-align: center;
		display: table-cell;
		vertical-align: middle;

		img {
			max-width: 146px;
			margin: 0 auto;
			padding-top: 20px;
		}
	}

	.content-info {
		// background: #ccc;
		// width: 100%;
		// display: table-cell;
		// vertical-align: middle;
	}
}

.right-content {
	border-radius: 0 4px 4px 0;
	padding: 0 50px 50px 50px;

	h3 {
		margin: 70px 0 20px;
	}
}

// FORM
.form-login-admin {
	// input {
	// 	background: #FAFAFA;
	// 	// @extend .inset-shadow;
	// 	box-shadow: inset 0px 1px 0 0px rgba(0, 0, 0, 0.1);
	// 	border: none;
	// 	border-radius: 0 !important;
	// 	padding: 6px;
	// }

	input {
		border: 1px solid #e4e4e4;
		border-radius: 0;

	}

	label {
		font-weight: 400;
	}

	.form-group {
		margin-bottom: 10px;
		.checkbox {
			float: left;
			width: 50%;
			margin: 0 0 0 2px;

			label {
				font-size: 12px;
				color: #B7B7B7;
			}
		}
	}

	.input-group-addon {
		border: none;
		border-radius: 0 !important;
		background: #FAFAFA;
		// @extend .inset-shadow;
		box-shadow: inset 0px 1px 0 0px rgba(0, 0, 0, 0.1);
		padding: 6px;
	}

	i {
		font-size: 18px;
		// color: $clr-light-info;
	}

	button{
		background: $main-black;
		border: none;
		border-radius: 2px;
		margin-bottom: 5px;
		width: 100%;
		// background: $clr-light-info;
		// padding: 6px 50px;

		&:hover {
			background: darken($main-black, 5%);
			// background: $clr-info;
		}
	}

	.forgot-items {
		margin: 10px 0;
		a {
			color: #B7B7B7;
			font-size: 12px;
		}

	}
}



